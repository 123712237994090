
export const containsMaliciousContent = (value) => {

    if (/^(https?:\/\/[^\s]+)$/i.test(value)) {
        return false;
    }

    const sqlInjectionPatterns = [
        /(\"|;|\b(DELETE|DROP|TRUNCATE)\b|(--|#|\/\*))/gi,
    ];

    const xssPatterns = [
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/i,
        /on\w+="[^"]*"/i,
        /javascript:/i,
        /[*<>]/g,
    ];

    if (sqlInjectionPatterns.some((regex) => regex.test(value))) {
        return true;
    }

    if (xssPatterns.some((regex) => regex.test(value))) {
        return true;
    }

    return false;
};
